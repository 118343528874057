@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/themes/dark.scss";
@import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./shared/colors.scss";

@font-face {
  font-family: "RobotoRegular";
  src: local("Oxygen"),
    url(./assets/fonts/ROBOTO/ROBOTO-REGULAR.TTF) format("truetype");
}

@font-face {
  font-family: "ProductSansRegular";
  src: local("Oxygen"),
    url(./assets/fonts/PRODUCTSANS/PRODUCTSANS-REGULAR.TTF) format("truetype");
}

html,
body {
  width: 100%;
  min-height: 100vh;
  height: auto;
  margin: 0;
}

body {
  font-family: "RobotoRegular" !important;
}

body {
  overflow-x: hidden;
}

.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-w-500 {
  font-weight: 500 !important;
}
.f-w-600 {
  font-weight: 600 !important;
}

.f-productSans {
  font-family: "ProductSansRegular" !important;
}

.b-b-none {
  border-bottom: none !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.w-50-px {
  width: 50px !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 10 !important;
}

.p-15 {
  padding: 15px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-r-l-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-t-b-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-t-b-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-5-px {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-15 {
  margin: 15px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-28 {
  margin-right: 28px !important;
}

.dis-block {
  display: block !important;
}

.w-0 {
  width: 0% !important;
}

.w-14 {
  width: 14% !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.h-55 {
  height: 55px !important;
}

.h-fit-content {
  height: fit-content;
}

.txt-end {
  text-align: end !important;
}

.curs_point {
  cursor: pointer !important;
}

.border-right-grey {
  border-right: 1px solid $primary-grey !important;
}

.w-fit-content {
  width: fit-content !important;
}

.txt-end {
  text-align: end !important;
}
.rad-10 {
  border-radius: 10px !important;
}

// .w-15 {
//   width: 15% !important;
// }
// @media (min-width: 800px) and (max-width: 2000px) {
//   .w-15 {
//     width: 15% !important;
//   }
// }

@media (min-width: 500px) and (max-width: 1000px) {
  .w-15 {
    width: 25% !important;
  }

  .w-85 {
    width: 75% !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .main-side-menu {
    font-size: 15px !important;
  }

  .w-15 {
    width: 18% !important;
  }

  .w-85 {
    width: 82% !important;
  }
}

@media screen and (max-width: 700px) {
  .main-side-menu {
    font-size: 16px !important;
  }

  .w-15 {
    width: 30% !important;
  }

  .w-85 {
    width: 70% !important;
  }
}

// @media screen and (max-width: 550px) {
//  .w-15 {
//     width: 44% !important;
//   }

//   .w-85 {
//     width: 56% !important;
//   }
// }

@media screen and (max-width: 750px) {
  .main-side-menu {
    font-size: 13px !important;
  }
}

@media (min-width: 1200px) {
  .w-15 {
    width: 15% !important;
  }

  .w-85 {
    width: 85% !important;
  }
}

// .w-85 {
//   width: 85% !important;
// }

/** dropdown z-index **/
.ngx-datatable {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.datatable-body-cell {
  overflow-x: visible !important;
}

.ngx-datatable.scroll-horz .datatable-body {
  overflow-x: visible;
}

.datatable-body-cell {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.ngx-datatable {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

/** **/
.visible {
  display: inline;
}

.hidden {
  display: none;
}

.spn_required {
  color: red;
  font-weight: bold;
}

.circle_tab {
  // margin: 0 10px 0 10px;
  // border-radius: 50%;
  // width: 25px;
  // height: 25px;
  // padding: 8px;
  // background:  $main-toolbar-color;
  // text-align: center;
  // color: white;
  // font: 11px Arial, sans-serif;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  padding: 6px;
  margin: 0 7px 0 7px;
  background: grey;
  color: white;
  text-align: center;
  font: 17px Arial, sans-serif;
}

.grid-div {
  display: grid !important;
}

.datatable-header-cell-label {
  font-size: 18px !important;
}

.datatable-body-cell-label {
  font-size: 16px !important;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .btn_profile_email {
    display: none;
  }

  .tbl_cust_phone_col {
    display: none !important;
  }

  .tbl_cust_org_col {
    display: none !important;
  }

  .tbl_cust_domain_col {
    width: 50% none !important;
  }
}

@media (max-width: 768px) {
  .tbl_cust_phone_col {
    display: none !important;
  }
}

.btn_blue_bg {
  background-color: $primary-blue-dark;
  margin: 10px;
  color: white;
  font-weight: 400;
  padding: 6px 20px 6px 20px;
  border-color: $primary-blue-dark;
  cursor: pointer;
  border: 1px solid;
  border-radius: 3px;
}

.btn_blue_bg:hover,
.btn_blue_bg:focus {
  // color: $primary-blue-dark;
  // background-color: white;
  // border: 1px solid;
  // border-color: $primary-blue-dark;
  background-color: #296d92;
}

.btn_red_bg {
  background-color: $primary-red;
  margin: 10px;
  color: white;
  border-radius: 7px;
  padding: 2px;
  border-color: white;
  cursor: pointer;
}

.btn_red_bg:hover,
.btn_red_bg:focus {
  color: white;
  background-color: #ac233c;
}

.btn_white_bg {
  background: white;
  margin: 0 15px 0 15px;
  color: $primary-dark-blue;
  border-radius: 5px;
  border-color: $primary-dark-blue;
  cursor: pointer;
}

.btn_white_bg:hover,
.btn_white_bg:focus {
  background-color: $primary-dark-blue;
  color: white;

  .fa,
  .fa-arrow-up {
    color: white !important;
  }
}

/** DATATABLE **/
.datatable-footer-inner {
  background-color: $primary-dark-blue !important;
}

ngx-datatable {
  margin: 0 !important;
  padding: 0 !important;
}

// datatable-header-cell {
//   border: none !important;
// }

//
.btn_cancel {
  background: $main-toolbar-color;
  margin: 10px;
  padding: 8px;
  color: white;
  border-radius: 5px;
  border-color: $main-toolbar-color;
  cursor: pointer;
}

.btn_cancel:hover,
.btn_cancel:focus {
  background-color: white;
  color: $main-toolbar-color;
}

.div_table {
  margin: 0 10px 0 0 !important;
  border: 2px solid #d6d9db !important;
  padding: 0 !important;
  border-radius: 10px;
}

.input_search {
  padding: 0 7px 0 7px !important;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border-radius: 3px;
}

.tbl_sub_edit_col {
  display: none !important;
}

.tbl_cust_edit_col {
  display: none !important;
}

/** DATE PICKER**/
.my-dp {
  height: 100% !important;
  border: 1px solid #0000008c;
  border-color: #b3b3b3 #ccc #d9d9d9 !important;
  padding-top: 0px;
  border-radius: 4px 0 0 4px !important;
}

.date-p {
  span.fa-calendar {
    font-size: 22px;
    padding: 5px 8px 5px 4px;
    border: 1px solid #0000008c;
    height: 35px !important;
    border-color: #b3b3b3 #ccc #d9d9d9 !important;
    border-radius: 0 4px 4px 0;
    width: 30px !important;
  }
}

//
.blue-border {
  border-color: $primary-light-blue !important;
  border: 2px solid;
}

.page_title {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.btn_plus_span {
  float: left;
  padding: 6px 17px 0 0px;
}

.ic_plus_white {
  padding-right: 7px;
  //   padding: 0 15px 0 0px;
  //   // background-image: url('./assets/+.png');
  //   background-repeat: no-repeat;
  //   // width:30px;
  //   // height:30px;
  //   border-style: none
}

// .ic_plus_white:hover {
//   background-image: url('./assets/header-add-button-hover.png');
// }

.ic_edit {
  background: url("./assets/edit-icon-normal.png");
  cursor: pointer;
  background-repeat: no-repeat;
  border-style: none;
}

.ic_edit:hover {
  background: url("./assets/edit-icon-hover.png");
}

.ic_add_header {
  width: 36px;
  height: 36px;
  background: url("./assets/header-add-button-normal.png");
  cursor: pointer;
  background-repeat: no-repeat;
  border-style: none;
}

.ic_add_header:hover {
  background: url("./assets/header-add-button-hover.png");
}

.ic_details {
  width: 32px;
  height: 32px;
  background: url("./assets/details-icon-normal.png");
  cursor: pointer;
  background-repeat: no-repeat;
}

.ic_details:hover {
  background-repeat: no-repeat;
  background: url("./assets/details-icon-hover.png");
}

.color_red {
  color: $primary-red;
  font-weight: bold;
}

.color_green {
  color: green;
  font-weight: bold;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  vertical-align: bottom;
  padding: 0.75rem;
  // border-bottom: 2px solid #d6d9db;
}

.ngx-datatable.bootstrap .datatable-header {
  border-bottom: 2px solid #d6d9db;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border: none !important;
}

// .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
//   background-color: white !important;
//   border-top: 2px solid !important;
//   border-color: $grey-blue !important;
// }
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  background-color: white !important;
  border-bottom: 2px solid !important;
  border-top: none !important;
  border-color: #d6d9db !important;
  width: 100% !important;
}

.ngx-datatable .datatable-body .datatable-scroll {
  width: 100% !important;
}

.ngx-datatable .datatable-row-center {
  width: 100% !important;
}

.ngx-datatable .datatable-header .datatable-header-inner {
  width: 100% !important;
}

.ngx-datatable .datatable-footer .datatable-footer-inner {
  background-color: white !important;
}

.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  .datatable-icon-left,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  .datatable-icon-skip,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  .datatable-icon-right,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  .datatable-icon-prev {
  color: $primary-blue-dark !important;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  color: $primary-blue-dark !important;
}

.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  background-color: $primary-blue-dark !important;
  color: white !important;
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
  color: $primary-blue-dark !important;
  font-weight: bold !important;
}

.datatable-body-cell-label {
  font-size: 16px !important;
  font-weight: 500;
}

.datatable-header-cell-label {
  font-size: 17px !important;
}

.ic_back_arrow {
  transform: rotate(180deg);
}

.txt_back_head {
  color: $primary-blue-dark;
  font-size: 18px;
}

.form_title {
  font-size: 18px;
  font-weight: bold;
}

.cust_title {
  font-size: 16px;
  font-weight: bold;
}

form {
  input {
    height: 40px !important;
  }
}

.btn_disabled {
  background: grey;
  margin: 10px;
  color: white;
  font-weight: 400;
  padding: 6px 20px 6px 11px;
  border-color: $primary-blue-dark;
  cursor: pointer;
  border: 1px solid;
  border-radius: 3px;
  cursor: not-allowed !important;
}

.not_clickable {
  pointer-events: none !important;
  cursor: none !important;
}

@media only screen and (max-width: 1000px) {
  .col_remove {
    display: none !important;
  }

  .img_usr_profile {
    width: 12% !important;
  }
}

@media screen and (max-width: 1000px) and (min-width: 700px) {
  .btn_cust_ord {
    margin: 0 0px 0 0 !important;
  }
}

@media screen and (max-width: 1000px) and (min-width: 760px) {
  .img_usr_profile {
    width: 6% !important;
  }
}

@media only screen and (max-width: 760px) {
  .img_usr_profile {
    width: 3% !important;
  }

  .btn_blue_bg {
    padding: 1px 7px 1px 7px !important;
  }
}

.sp_popup_close {
  color: $primary-grey;
  border: 1px solid;
  border-radius: 50%;
  padding: 3px;
  text-align: center;
  width: 23px;
  height: 23px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  background-color: blue !important;
  color: blue !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: $primary-blue-light !important;
}

.btn_select {
  color: $primary-dark-blue;
  padding: 7px 15px 7px 15px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
}

.btn_select:hover,
.btn_select:focus {
  cursor: pointer;
  background-color: $grey-blue;
}

//input/ floating label
border-lable-flt {
  display: block;
  position: relative;
}

.border-lable-flt label,
.border-lable-flt > span {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  top: -0.5em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  background-color: white !important;
  margin-left: 15px;
  color: $primary-blue-light;
}

.border-lable-flt label::after,
.border-lable-flt > span::after {
  content: " ";
  display: block;
  position: absolute;
  background: white;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}

.border-lable-flt .form-control::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.border-lable-flt
  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.border-lable-flt .form-control:placeholder-shown:not(:focus) + * {
  font-size: 15px;
  opacity: 0.5;
  top: 0.3em;
  color: #484949;
  padding-top: 7px;
  font-weight: 400 !important;
}

.input-group .border-lable-flt {
  display: table-cell;
}

.input-group .border-lable-flt .form-control {
  border-radius: 0.25rem;
}

.input-group .border-lable-flt:not(:last-child),
.input-group .border-lable-flt:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  // border-right: 0;
}

.input-group .border-lable-flt:not(:first-child),
.input-group .border-lable-flt:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-control:focus {
  color: #804000;
  background-color: red;
  border-color: #006633;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 51, 0.25);
}

//
.invalid-feedback {
  display: block !important;
}
.btn_upload_file {
  width: fit-content;
  height: fit-content;
  padding: 5px;
}
#div_policies {
  overflow-x: scroll !important;
  table {
    text-align: center !important;
  }
  th,
  td {
    padding: 7px !important;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
