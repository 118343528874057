$main-toolbar-color : #333;
$btn-shadow: #eae7e7; 
$primary-dark-blue : #005b7c;
$primary-light-blue : #00aeed;

$primary-blue : #1a73e8;


$primary-blue-dark : #00517D;
$primary-blue-light:  #28BFEF;
$primary-red :  #B2291F;
$grey-blue:#e6eef2;
$primary-grey:#cdcece;
$drak-grey: #a1a1a1;

